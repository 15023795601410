import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {
  const transition ={type :'spring' , duration:3}
  const mobile=window.innerWidth<=768 ? true : false
  return (
    <div className='hero' id="home">
      <div className='blur hero-blur'></div>
      <div className='left-h'>
      <Header/>
      {/* The best ad */}
      <div className='the-best-ad'>
        <motion.div
         initial={{left:mobile? "178px":"238px"}}
         whileInView={{left:'8px'}}
         transition={{...transition,type:'tween'}}
        ></motion.div>
        <span>The best fitness club in the town</span>
      </div>
      {/* Hero Head */}
      <div className='hero-text'>
        <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
        </div>
        <div>
            <span>
                Ideal Body
            </span>
        </div>
        <div>
            <span>Gel ve üye ol değişikliği hisset :) Gel ve üye ol değişikliği hisset :)
            Gel ve üye ol değişikliği hisset :)
            </span>
        </div>
      </div>
      {/* figuress*/}
      <div className='figures'>
        <div>
            <span>
              <NumberCounter end={140} start={60} delay='4' preFix='+'></NumberCounter>
            </span>
            <span>Experts coaches</span>
        </div>
        <div>
            <span>  <NumberCounter end={978} start={750} delay='4' preFix='+'></NumberCounter></span>
            <span>Members Joined</span>
        </div>
        <div>
            <span>  <NumberCounter end={50} start={1} delay='4' preFix='+'></NumberCounter></span>
            <span>fitness programs</span>
        </div>
      </div>
      {/*Hero Button */}
      <div className='hero-buttons'>
        <button className="btn">Get Started</button>
        <button className="btn">Learn More</button>       
      </div>
      </div>
      <div className='right-h'>
        <button className='btn'>Join Now</button>
        <motion.div
        transition={transition}
        whileInView={{right:'4rem'}}
        initial={{right:'-1rem'}}
        className='heeat-rate'>
            <img src={Heart} alt=''/>
            <span>Heart Rate</span>
            <span>116 BPM</span>
        </motion.div>
    {/* Hero İmages */}
        <img src={hero_image} alt='' className='hero-image'/>
        <motion.img
         transition={transition}
         whileInView={{right:'20rem'}}
         initial={{right:'11rem'}}
        
        src={hero_image_back} alt='' className='hero-image-back'/>

        {/*Calories */}
        <motion.div
        transition={transition}
        whileInView={{right:'28rem'}}
        initial={{right:'37rem'}}
        
        className='calories'>
        <img src={Calories} alt='' />  
        <div>
           <span>Calories burned </span>
           <span>220 cal</span>  
        </div>
       
        </motion.div>
      </div>
    </div>
  )
}

export default Hero
